import { FC } from "react"
import { StyledYaReviews } from "@/layouts/Default/Footer/YaReviews/StyledYaReviews"

const YaReviewsWidget: FC = () => {
  return (
    <StyledYaReviews
      dangerouslySetInnerHTML={{
        __html: `<iframe src="https://yandex.ru/sprav/widget/rating-badge/143022984935?type=rating" width="150" height="50"  frameborder="0"></iframe>`,
      }}
    />
  )
}

export { YaReviewsWidget }
