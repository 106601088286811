import { ApiError } from "next/dist/server/api-utils"
import { Controller, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { FC, useContext, useEffect, useState } from "react"
import {
  fetchToCommercialDepartment,
  ToCommercialDepartmentRequestType,
} from "@/api/feedbackAPI"
import {
  FeedbackTopicHash,
  FeedbackTranslit,
  sendMessageFeedback,
  toCodeFormat,
  toMessageFormatPayload,
} from "@/hooks/feedback"
import { useGRecaptcha } from "@/hooks/gReCaptcha"
import { useAppSelector } from "@/hooks/redux"
import { Button } from "@/ui/Button/Button"
import { Field } from "@/ui/Field/Field"
import { PhoneField } from "@/ui/Field/PhoneField"
import { ModalContext } from "@/ui/Modal/Modal"
import { Select } from "@/ui/Select/Select"
import { SelectItemsType } from "@/ui/Select/types"
import { SingleError, Typography } from "@/ui/Typography/Typography"
import { GTAG_EVENTS, TIMEOUT_SUCCESS } from "@/utils/constants"
import { gtagSend } from "@/utils/common/helpers"
import { getPhoneWithCode, RULES_PHONE_VALIDATE } from "@/validations/phone"
import { FormGroup, StyledForm, StyledFormWrapper } from "../StyledForms"
import { SuccessOverlay } from "../SuccessOverlay/SuccessOverlay"

type ToCommercialDepartmentType = ToCommercialDepartmentRequestType & {
  dataPrivacyAgreement: boolean
}

export const ToCommercialDepartment: FC = () => {
  const categoriesTree = useAppSelector(
    (state) => state.categories.categories?.treeSorted || [],
  )
  const [error, setError] = useState<string | null>(null)

  const modalContext = useContext(ModalContext)

  const [categoriesItems, setCategoriesItems] = useState<SelectItemsType[]>([])

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
    reset: resetForm,
    getValues,
  } = useForm<ToCommercialDepartmentType>({
    defaultValues: {
      brand: "",
      category: "",
      fio: "",
      representation: "",
      site_company: "",
      social_brand: "",
      status: "",
      phone: "",
      email: "",
      info: "",
      dataPrivacyAgreement: false,
    },
    mode: "onChange",
  })

  const {
    reset: resetMutation,
    mutate: commercialDepartmentMutate,
    isSuccess,
    isLoading,
  } = useMutation(fetchToCommercialDepartment, {
    onMutate: () => {
      setError(null)
    },
    onSuccess: (_, request) => {
      gtagSend({
        event: GTAG_EVENTS.MESSAGE_COMMERS,
      })
      resetForm({
        brand: "",
        category: "",
        fio: "",
        representation: "",
        site_company: "",
        social_brand: "",
        status: "",
        phone: "",
        email: "",
        info: "",
      })

      sendMessageFeedback({
        text: toMessageFormatPayload({
          hash: FeedbackTopicHash.feedback,
          topic: "Обратная связь",
          payload: [
            {
              label: "ФИО",
              value: request.fio,
            },
            {
              label: FeedbackTranslit.phone,
              value: toCodeFormat(request.phone),
            },
            {
              label: FeedbackTranslit.email,
              value: request.email,
            },
            {
              label: "Название бренда",
              value: request.brand,
            },
            {
              label: "Категория продукта",
              value: request.category,
            },
            {
              label: "Статус",
              value: request.status,
            },
            {
              label: "Представленность в крупных сетевых магазинах",
              value: request.representation,
            },
            {
              label: "Сайт компании/бренда",
              value: request.site_company || "",
            },
            {
              label: "Социальная сеть бренда",
              value: request.social_brand || "",
            },
            {
              label: "Дополнительная информация",
              value: request.info || "",
            },
          ],
        }),
      })

      setTimeout(() => {
        resetMutation()
        modalContext?.hide()
      }, TIMEOUT_SUCCESS)
      setError(null)
    },
    onError: (err: ApiError) => {
      setError(err.message)
    },
  })

  const { run, isLoading: isLoadingReCaptcha } = useGRecaptcha({
    cbMutate: () => {
      setError(null)
    },
    cbSuccess: () => {
      commercialDepartmentMutate({
        fio: getValues("fio"),
        status: getValues("status"),
        social_brand: getValues("social_brand"),
        site_company: getValues("site_company"),
        representation: getValues("representation"),
        category: getValues("category"),
        brand: getValues("brand"),
        info: getValues("info"),
        email: getValues("email"),
        phone: getPhoneWithCode(getValues("phone")),
      })
    },
    cbError: (res) => {
      setError(res?.message || null)
    },
  })

  const onSubmit = handleSubmit(() => {
    run()
  })

  useEffect(() => {
    setCategoriesItems(
      categoriesTree.map((category) => {
        return {
          value: category.uuid || "",
          name: category.name || "",
        }
      }),
    )
  }, [categoriesTree])

  return (
    <>
      <StyledFormWrapper>
        <SuccessOverlay isSuccess={isSuccess} />
        <StyledForm onSubmit={onSubmit}>
          <Typography variant={"p14"}>
            для оформления заявки заполните <b>7&nbsp;обязательных полей</b>
          </Typography>
          <Typography variant={"h2"}>О продукте</Typography>
          <FormGroup>
            {categoriesItems.length > 0 && (
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Поле обязательно для заполнения",
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      ariaLabel={"Категория продукта"}
                      initialValue={field.value || ""}
                      items={categoriesItems}
                      variant={"default"}
                      onSelectValue={(value) => {
                        field.onChange(value)
                      }}
                      ref={field.ref}
                      errorMessage={fieldState.error?.message}
                      required={true}
                    />
                  )
                }}
                control={control}
                name={"category"}
              />
            )}
          </FormGroup>
          <FormGroup>
            <Controller
              name={"brand"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Поле обязательно для заполнения",
                },
              }}
              render={({ field, fieldState }) => (
                <Field
                  type={"text"}
                  value={field.value}
                  placeholder={"Название бренда"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                  required={true}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"representation"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Поле обязательно для заполнения",
                },
              }}
              render={({ field, fieldState }) => (
                <Field
                  variant={"textarea"}
                  type={"text"}
                  value={field.value}
                  placeholder={"Представленность в крупных сетевых магазинах"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                  ref={field.ref}
                  required={true}
                  style={{
                    minHeight: "80px",
                  }}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"site_company"}
              control={control}
              render={({ field, fieldState }) => (
                <Field
                  type={"text"}
                  value={field.value}
                  placeholder={"Сайт компании/бренда"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"social_brand"}
              control={control}
              render={({ field, fieldState }) => (
                <Field
                  type={"text"}
                  value={field.value}
                  placeholder={"Социальная сеть бренда"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                />
              )}
            />
          </FormGroup>
          <Typography variant={"h2"}>Представитель</Typography>
          <FormGroup>
            <Controller
              name={"status"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Поле обязательно для заполнения",
                },
              }}
              render={({ field, fieldState }) => (
                <Select
                  ariaLabel={"Ваш статус"}
                  initialValue={field.value || ""}
                  items={[
                    {
                      name: "дистрибьютор",
                      value: "дистрибьютор",
                    },
                    {
                      name: "производитель",
                      value: "производитель",
                    },
                  ]}
                  variant={"default"}
                  onSelectValue={(value) => {
                    field.onChange(value)
                  }}
                  ref={field.ref}
                  errorMessage={fieldState.error?.message}
                  required={true}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"fio"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Поле обязательно для заполнения",
                },
              }}
              render={({ field, fieldState }) => (
                <Field
                  type={"text"}
                  value={field.value}
                  placeholder={"ФИО представителя"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                  required={true}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Поле обязательно для заполнения",
                },
              }}
              render={({ field, fieldState }) => (
                <Field
                  type={"text"}
                  value={field.value}
                  placeholder={"Email"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                  required={true}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              rules={RULES_PHONE_VALIDATE}
              render={({ field, fieldState }) => (
                <PhoneField
                  value={field.value}
                  onValueChange={({ value }) => {
                    field.onChange(value)
                  }}
                  errorMessage={fieldState?.error?.message}
                  required={true}
                />
              )}
              control={control}
              name={"phone"}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name={"info"}
              control={control}
              render={({ field, fieldState }) => (
                <Field
                  variant={"textarea"}
                  type={"text"}
                  value={field.value}
                  placeholder={"Дополнительная информация"}
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  withAnimatingLabel
                  ref={field.ref}
                />
              )}
            />
          </FormGroup>

          {error !== null && <SingleError>{error}</SingleError>}

          <Button
            type={"submit"}
            variant={"filled"}
            size={"large"}
            disabled={Object.keys(errors).length > 0 || !isDirty}
            isFetching={isLoading || isSubmitting || isLoadingReCaptcha}
          >
            Отправить
          </Button>
        </StyledForm>
      </StyledFormWrapper>
    </>
  )
}
