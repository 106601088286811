import { cx } from "@linaria/core";
import { FC, memo } from "react";
import { cssIsActive } from "@/styles/utils/Utils";
import { Icon } from "@/ui/Icon";
import { Typography } from "@/ui/Typography/Typography";
import { StyledSuccessOverlay } from "./StyledSuccessOverlay";
export const SuccessOverlay: FC<{
  isSuccess: boolean;
  message?: string;
}> = memo(({
  isSuccess,
  message
}) => {
  if (!isSuccess) {
    return null;
  }
  return <StyledSuccessOverlay className={cx(isSuccess && cssIsActive)}>
        <Icon NameIcon={"Check"} size={"large"} />
        <Typography variant={"default"}>
          {!!message ? message : "Данные успешно отправлены!"}
        </Typography>
      </StyledSuccessOverlay>;
});
SuccessOverlay.displayName = "SuccessOverlay";